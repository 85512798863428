import React from 'react';
import styled from '@emotion/styled';

import { Banner, Content, ContactBox } from '@/components/molecules';
import { PrismicEntretienPage } from '@/domain/PrismicEntretienPage';

interface EntretienContainerProps {
    data: {
        prismicEntretienPage: PrismicEntretienPage;
    };
    lang: string;
}

const EntretienContainerRoot = styled.section``;

const EntretienContainer = ({ data, lang }: EntretienContainerProps): JSX.Element => {
    const { entretien_content } = data?.prismicEntretienPage?.data;

    const ContentData = {
        content: entretien_content?.raw
    }

    return (
        <EntretienContainerRoot>
            <Banner lang={lang} />
            <Content {...ContentData} />
            <ContactBox lang={lang} />
        </EntretienContainerRoot>
    );
};

export default EntretienContainer;